<template>
  <div>
    <h2 class="px-4">
      <i class="pi pi-cog"></i>
      <span>{{ $t('settings') }}</span>
    </h2>
  </div>
</template>

<style scoped>
.pi-cog {
  font-size: 1.25rem;
  margin-right: 0.5rem;
}
.version-tag {
  margin-left: 0.5rem;
}
</style>
