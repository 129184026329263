export default {
  download: 'Скачать',
  refresh: 'Обновить',
  loadAllFolders: 'Загрузить все папки',
  terminal: 'Терминал',
  videoFailedToLoad: 'Видео не удалось загрузить',
  extensionName: 'Название расширения',
  reloadToApplyChanges: 'Перезагрузите, чтобы применить изменения',
  insert: 'Вставить',
  systemInfo: 'Информация о системе',
  devices: 'Устройства',
  about: 'О',
  add: 'Добавить',
  confirm: 'Подтвердить',
  reset: 'Сбросить',
  resetKeybindingsTooltip: 'Сбросить сочетания клавиш по умолчанию',
  customizeFolder: 'Настроить папку',
  icon: 'Иконка',
  color: 'Цвет',
  bookmark: 'Закладка',
  folder: 'Папка',
  star: 'Звёздочка',
  heart: 'Сердце',
  file: 'Файл',
  inbox: 'Входящие',
  box: 'Ящик',
  briefcase: 'Чемодан',
  error: 'Ошибка',
  loading: 'Загрузка',
  findIssues: 'Найти Issue',
  copyToClipboard: 'Копировать в буфер обмена',
  openNewIssue: 'Открыть новый Issue',
  showReport: 'Показать отчёт',
  imageFailedToLoad: 'Изображение не удалось загрузить',
  reconnecting: 'Переподключение',
  reconnected: 'Переподключено',
  delete: 'Удалить',
  rename: 'Переименовать',
  customize: 'Настроить',
  experimental: 'БЕТА',
  deprecated: 'УСТАР',
  loadWorkflow: 'Загрузить рабочий процесс',
  goToNode: 'Перейти к узлу',
  settings: 'Настройки',
  searchWorkflows: 'Поиск рабочих процессов',
  searchSettings: 'Поиск настроек',
  searchNodes: 'Поиск узлов',
  searchModels: 'Поиск моделей',
  searchKeybindings: 'Поиск сочетаний клавиш',
  searchExtensions: 'Поиск расширений',
  noResultsFound: 'Ничего не найдено',
  searchFailedMessage:
    'Не удалось найти ни одной настройки, соответствующей вашему запросу. Попробуйте скорректировать поисковый запрос.',
  noContent: '(Нет контента)',
  noTasksFound: 'Задачи не найдены',
  noTasksFoundMessage: 'В очереди нет задач.',
  newFolder: 'Новая папка',
  sideToolbar: {
    themeToggle: 'Переключить тему',
    queue: 'Очередь',
    nodeLibrary: 'Библиотека узлов',
    workflows: 'Рабочие процессы',
    browseTemplates: 'Просмотреть примеры шаблонов',
    openWorkflow: 'Открыть рабочий процесс в локальной файловой системе',
    newBlankWorkflow: 'Создайте новый пустой рабочий процесс',
    nodeLibraryTab: {
      sortOrder: 'Порядок сортировки'
    },
    modelLibrary: 'Библиотека моделей',
    queueTab: {
      showFlatList: 'Показать плоский список',
      backToAllTasks: 'Вернуться ко всем задачам',
      containImagePreview: 'Предпросмотр заливающего изображения',
      coverImagePreview: 'Предпросмотр подходящего изображения',
      clearPendingTasks: 'Очистить отложенные задачи'
    }
  },
  menu: {
    hideMenu: 'Скрыть меню',
    showMenu: 'Показать меню',
    batchCount: 'Количество пакетов',
    batchCountTooltip:
      'Количество раз, когда генерация рабочего процесса должна быть помещена в очередь',
    autoQueue: 'Автоочередь',
    disabled: 'Отключено',
    disabledTooltip: 'Рабочий процесс не будет автоматически помещён в очередь',
    instant: 'Мгновенно',
    instantTooltip:
      'Рабочий процесс будет помещён в очередь сразу же после завершения генерации',
    change: 'При изменении',
    changeTooltip:
      'Рабочий процесс будет поставлен в очередь после внесения изменений',
    queueWorkflow: 'Очередь рабочего процесса (Shift для вставки спереди)',
    queueWorkflowFront: 'Очередь рабочего процесса (Вставка спереди)',
    queue: 'Очередь',
    interrupt: 'Отменить текущее выполнение',
    refresh: 'Обновить определения узлов',
    clipspace: 'Открыть Clipspace',
    resetView: 'Сбросить вид холста',
    clear: 'Очистить рабочий процесс'
  },
  templateWorkflows: {
    title: 'Начните работу с шаблона',
    template: {
      default: 'Image Generation',
      image2image: 'Image to Image',
      upscale: '2 Pass Upscale',
      flux_schnell: 'Flux Schnell'
    }
  },
  graphCanvasMenu: {
    zoomIn: 'Увеличить',
    zoomOut: 'Уменьшить',
    resetView: 'Сбросить вид',
    fitView: 'Подгонять под выделенные',
    selectMode: 'Выбрать режим',
    panMode: 'Режим панорамирования',
    toggleLinkVisibility: 'Переключить видимость ссылок'
  }
}
